import React from 'react';
import MainNav from '../Components/MainNav.js';
import paymentSuccess from '../../assets/img/green_double_circle_check_mark.jpg';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { roundOfToInt } from 'utils/formattingUtils';
import { useUserProfile } from 'profiles/queries/profileHooks.js';
import Loader from '../../components/Loader';
import { usePaymentStatus } from 'registration/queries/hooks.js';

export default function userPaymentSuccess() {
  // const params = useParams();
  const history = useHistory();
  const userQuery = useUserProfile();
  const params = useParams();
  const { search } = useLocation();
  const alreadyPaid = new URLSearchParams(search).get('alreadyPaid');
  const PaymentStatus = usePaymentStatus({
    tournamentId: params.tournament_id,
  });
  // const client = useQueryClient();
  // useEffect(() => {
  //   client.refetchQueries();
  // }, []);
  let amount = 0;
  console.log(userQuery);
  if (
    !history?.location?.state?.cartSummary &&
    userQuery.isFetched &&
    userQuery?.data?.message?.primary_role !== 'institution'
  )
    history.push(`/${userQuery?.data?.message?.primary_role}/profiles/profile`);
  if (history?.location?.state?.cartSummary) {
    const { total = 0, taxes = [] } = history?.location?.state?.cartSummary;
    amount = roundOfToInt(total + taxes.reduce((el, el2) => el.amount + el2.amount, { amount: 0 }));
  }
  if (
    userQuery.isFetching ||
    (PaymentStatus.isFetching && userQuery?.data?.message?.primary_role === 'institution')
  )
    return (
      <Loader
        className="mt-20"
        text={'RE-DIRECTING. DO NOT CLICK BACK BUTTON OR REFRESH THE PAGE.'}
      />
    );
  console.log(PaymentStatus);
  return (
    <>
      <MainNav />
      <div className="px-4">
        <div
          className="relative bg-white text-gray-700 px-0 md:px-16 py-8 rounded-lg my-8 w-full md:w-10/12 font-roboto m-auto flex flex-col items-center justify-around text-base payment-success border border-gray-200"
          style={{ marginBottom: '250px' }}
        >
          <img
            src={paymentSuccess}
            alt="Payment Success"
            className="rounded-full  p-2 w-32 h-32 md:w-44 md:h-44"
          />
          <h2 className="mt-4 text-lg mx-1 md:mx-0 text-center">{`Hi ${userQuery?.data?.message?.full_name},`}</h2>
          <h1 className="text-2xl font-bold my-1">
            {alreadyPaid === 'true' ? 'You have already Paid!' : 'Payment Successful'}
          </h1>
          <h3 className="text-lg font-bold">
            Total ₹{amount || PaymentStatus?.data?.message?.total || 0}
          </h3>
          {/* <p className="w-4/5 md:w-7/12 text-sm mt-4 mx-auto text-center">
            Your payment was successful. A copy of receipt was sent to your email address at{' '}
            <span className="font-bold">{userQuery?.data?.message?.email}</span>
          </p> */}
          {userQuery?.data?.message?.primary_role === 'institution' ? (
            <button
              className="text-sm font-semibold text-center text-white py-2 px-6 rounded bg-gray-750 border border-gray-750 hover:border-transparent transition-colors duration-200 hover:bg-blue-800 mt-4"
              onClick={() => {
                window.open(
                  `/api/method/sfa_online.v1.registrations.cart.call_download_pdf?invoice=${PaymentStatus.data?.message?.invoice}`,
                  '_blank'
                );
              }}
            >
              Download Receipt
            </button>
          ) : null}
          {/* <Link className="mt-4 font-bold text-gray-750" to="/">
              Go to Profile
            </Link> */}
        </div>
      </div>
    </>
  );
}
